import { Injectable } from "@angular/core";
import { Either } from "@core/helpers/either";
import { Failure } from "@core/helpers/failure";
import { UseCase } from "@core/helpers/use-case";
import { PaginatedParams } from "@domain/models/paginated-params";
import { Service } from "@domain/models/service";
import { AdminRepository } from "@domain/repository/admin-repository";

@Injectable({
  providedIn: "root",
})
export class GetServicesUsecase implements UseCase<{ services: Service[], totalCount: number }, PaginatedParams> {
  constructor(private _adminRepository: AdminRepository) {}

  async execute(params: PaginatedParams): Promise<Either<Failure, { services: Service[], totalCount: number }>> {
    return await this._adminRepository.getServices(params);
  }
}
