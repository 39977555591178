import { Injectable } from "@angular/core";
import { Either } from "@core/helpers/either";
import { Failure } from "@core/helpers/failure";
import { UseCase } from "@core/helpers/use-case";
import { Agreements } from "@domain/models/agreements";
import { PaginatedParams } from "@domain/models/paginated-params";
import { AdminRepository } from "@domain/repository/admin-repository";

@Injectable({
  providedIn: "root",
})
export class GetAgreementsUsecase
  implements
    UseCase<{ agreements: Agreements[]; totalCount: number }, PaginatedParams>
{
  constructor(private _adminRepository: AdminRepository) {}

  async execute(
    params: PaginatedParams
  ): Promise<
    Either<Failure, { agreements: Agreements[]; totalCount: number }>
  > {
    return await this._adminRepository.getAgreements(params);
  }
}
