import { Injectable } from "@angular/core";
import { Either } from "@core/helpers/either";
import { Failure } from "@core/helpers/failure";
import { UseCase } from "@core/helpers/use-case";
import { BeneficiarieModel } from "@domain/models/beneficiarie.model";
import { PaginatedParams } from "@domain/models/paginated-params";
import { AdminRepository } from "@domain/repository/admin-repository";

@Injectable({
    providedIn: "root",
  })
  export class GetBeneficiariesUsecase implements UseCase<{ beneficiaries: BeneficiarieModel[], totalCount: number }, PaginatedParams> {
    constructor(private _adminRepository: AdminRepository) {}
  
    async execute(params: PaginatedParams): Promise<Either<Failure, { beneficiaries: BeneficiarieModel[], totalCount: number }>> {
      return await this._adminRepository.getBeneficiaries(params);
    }
  }