import { Either } from "@core/helpers/either";
import { Failure } from "@core/helpers/failure";
import { Agreements } from "@domain/models/agreements";
import { BeneficiarieModel } from "@domain/models/beneficiarie.model";
import { PaginatedParams } from "@domain/models/paginated-params";
import { Service } from "@domain/models/service";

export abstract class AdminRepository {
    abstract getServices(params: PaginatedParams): Promise<Either<Failure, { services: Service[], totalCount: number }>>;
    abstract addServices(data:Service ): Promise<Either<Failure, Service>>;
    abstract getBeneficiaries(params: PaginatedParams): Promise<Either<Failure, { beneficiaries: BeneficiarieModel[], totalCount: number }>>
    abstract getAgreements(params: PaginatedParams): Promise<Either<Failure, { agreements: Agreements[], totalCount: number }>>
}