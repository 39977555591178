import { Injectable } from "@angular/core";
import { Either } from "@core/helpers/either";
import { Failure } from "@core/helpers/failure";
import { UseCase } from "@core/helpers/use-case";
import { Service } from "@domain/models/service";
import { AdminRepository } from "@domain/repository/admin-repository";

@Injectable({
    providedIn: "root",
  })
  export class addServicesUsecase implements UseCase<Service,Service> {
    constructor(private _adminRepository: AdminRepository) {}
  
    async execute(params: Service): Promise<Either<Failure, Service>> {
      return await this._adminRepository.addServices(params);
    }
  }